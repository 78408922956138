<template>
  <div>
    <div id="pageCancellation" class="mainContents">
      <section class="titleArea">
        <h2>解約お手続き</h2>
        <!-- /.titleArea -->
      </section>

      <form>
        <div class="cancellationArea">
          <div class="inner">
            <h3>解約される登録内容をご確認ください</h3>
            <dl>
              <dt>お名前</dt>
              <dd>{{ u.full_name }}</dd>
              <dt>ご登録メールアドレス</dt>
              <dd>{{ u.email }}</dd>
            </dl>
            <h3>解約理由をお選びください<span>※必須 複数回答可</span></h3>
            <ul>
              <li v-for="(reason, index) in reasonList" :key="index">
                <label><input
                  v-model="reasons"
                  type="checkbox"
                  :value="reason"
                ><span>{{ reason }}</span></label>
              </li>
            </ul>
            <h3>コメント</h3>
            <div class="inputWrap">
              <textarea v-model="comment" placeholder="" />
            </div>

            <h3>パスワード</h3>
            <div class="inputWrap">
              <input
                v-model="password"
                type="password"
                placeholder="現在のパスワード"
              >
            </div>

            <p class="caution">
              「解約する」ボタンをクリックしていただくと解約が完了します。
            </p>

            <p v-if="error" class="errorMssage">
              {{ error }}
            </p>

            <div class="buttonArea">
              <p class="submit">
                <button
                  type="submit"
                  class="button"
                  :disabled="formInvalid"
                  @click="deleteUser($event)"
                >
                  解約する
                </button>
              </p>
              <!-- /.buttonArea -->
            </div>
          </div>
          <!-- /.cancellationArea -->
        </div>
      </form>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import { fieldNotNull } from '@/helpers/validator.js'

export default {
  props: {
    u: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isUploading: false,
      password: null,
      comment: null,
      reasons: [],
      reasonList: [
        '操作方法がわかりづらい',
        'サイトの表示スピードが遅い',
        '利用頻度が減った',
        'その他'
      ],
      error: null
    }
  },
  computed: {
    validPassword: function () {
      return fieldNotNull(this.password)
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validPassword) {
        return true
      }

      if (this.reasons.length < 1) {
        return true
      }

      return false
    }
  },
  created: function () {
    this.user = this.u
  },
  methods: {
    deleteUser (event) {
      event.preventDefault()

      this.error = null
      this.isUploading = true
      NProgress.start()

      this.$store
        .dispatch('user/deleteUser', {
          id: this.user.id,
          reasons: this.reasons,
          comment: this.comment,
          password: this.password
        })
        .then(res => {
          this.isUploading = false

          if (res.data.errors) {
            this.error = res.data.errors[0].message
          } else {
            this.$store.dispatch('logout')
            this.$router.push({ name: 'user-delete-complete' }).catch(() => {})
          }

          NProgress.done()
        })
        .catch(() => {
          this.isUploading = false
          NProgress.done()
        })
    }
  }
}
</script>
